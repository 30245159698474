<template>
  <div class="home">
    <div>
      <div>
        <div class="scene scene--card">
          <div
            class="card vertical"
            :class="{ 'is-flipped': clubsIsFlipped }"
            @mouseenter="flipClubs()"
          >
            <div class="card-face card-face--front suit clubs"></div>
            <div class="card-face card-face--back suit diamonds"></div>
          </div>
        </div>
      </div>
      <div>
        <div class="scene scene--card">
          <div
            class="card horizontal"
            :class="{ 'is-flipped': diamondsIsFlipped }"
            @mouseenter="flipDiamonds()"
          >
            <div class="card-face card-face--front suit diamonds"></div>
            <div class="card-face card-face--back suit spades"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="scene scene--card">
          <div
            class="card horizontal"
            :class="{ 'is-flipped': heartsIsFlipped }"
            @mouseenter="flipHearts()"
          >
            <div class="card-face card-face--front suit hearts"></div>
            <div class="card-face card-face--back suit clubs"></div>
          </div>
        </div>
      </div>
      <div>
        <div class="scene scene--card">
          <div
            class="card vertical"
            :class="{ 'is-flipped': spadesIsFlipped }"
            @mouseenter="flipSpades()"
          >
            <div class="card-face card-face--front suit spades"></div>
            <div class="card-face card-face--back suit hearts"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clubsIsFlipped: false,
      diamondsIsFlipped: false,
      heartsIsFlipped: false,
      spadesIsFlipped: false,
    };
  },
  methods: {
    flipClubs() {
      this.clubsIsFlipped = !this.clubsIsFlipped;
    },
    flipDiamonds() {
      this.diamondsIsFlipped = !this.diamondsIsFlipped;
    },
    flipHearts() {
      this.heartsIsFlipped = !this.heartsIsFlipped;
    },
    flipSpades() {
      this.spadesIsFlipped = !this.spadesIsFlipped;
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
  }
}

.scene {
  width: 140px;
  height: 140px;
  perspective: 600px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 2s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 6px;
}

.card.vertical.is-flipped {
  transform: rotateX(360deg);
}
.card.horizontal.is-flipped {
  transform: rotateY(360deg);
}

.card-face {
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

// .card-face--front {
//   // background: red;
// }

.card.vertical .card-face--back {
  transform: rotateX(180deg);
}
.card.horizontal .card-face--back {
  transform: rotateY(180deg);
}

</style>
