<template>
  <nav-menu></nav-menu>
  <div class="body">
  <router-view />
  </div>
  <!-- <div class="diagnostics">
    <pre><template v-for="(m, i) of messages" :key="i">{{ m }}<br></template></pre>
  </div> -->
</template>

<script>
import NavMenu from "./components/NavMenu.vue";
import router from "./router/index";
export default {
  name: "App",
  data() {
    return {
      messages: ["Starting..."],
    };
  },
  components: {
    NavMenu,
  },
  mounted() {
    var self = this;
    this.$connection.on("ReceiveMessage", function(user, message) {
      self.messages.push(message);
    });

  router.replace('/');
  },
};
</script>

<style lang="scss">

$bridge-green: #101f06;

html {    
    background-repeat: no-repeat;
    background-size: cover;
}
body {
  background: none;
}

$font-sans: "Roboto Condensed", sans-serif;
$font-script: "Shadows Into Light Two", cursive;

#app {
  font-family: $font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.body {
    min-width: 420px;
    position: absolute;
    top: 58px; // nav height
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("assets/baize_bg_1920.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.diagnostics {
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 30px;

    pre {
      height: 200px;
      color:#ddd;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
  font-weight: 300;
}

.suit {
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  background-size: contain;

  &.large {
    height: 60px;
    width: 60px;
  }

  &.clubs,
  &.suit0 {
    background-image: url("assets/club.svg");
  }
  &.diamonds,
  &.suit1 {
    background-image: url("assets/diamond.svg");
  }
  &.hearts,
  &.suit2 {
    background-image: url("assets/heart.svg");
  }
  &.spades,
  &.suit3 {
    background-image: url("assets/spade.svg");
  }
  &.notrumps,
  &.suit4 {
    background-image: url("assets/nt.svg");
  }
}
</style>
