<template>
    <div v-if="player" class="p-2">
        <h4>#{{ player.playerId }} {{ player.firstName }} {{ player.lastName }} &quot;{{player.handle}}&quot;</h4>
         <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th>Series</th>
            <th></th>
            <th class="text-right">Score</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s of player.seriesScores" :key="s.seriesId">
              <td>#{{s.seriesId}} {{s.seriesTitle}}</td>
              <td>{{s.rubberCount}} Rubbers</td>
              <td class="text-right">{{s.score}}</td>
          </tr>
        <tr>
              <td></td>
              <td>Total</td>
              <td class="text-right">{{scoreTotal}}</td>
          </tr>
        </tbody>
         </table>
    </div>
</template>

<script>
    export default {
        props: ['player'],
        computed: {
            scoreTotal() {
                var total = this.player.seriesScores.reduce(function(a,s){
                    return a + s.score;
                },0);
                return total;
            }
        }
    }
</script>

<style scoped lang="scss">

table {
    color: white;
}

</style>