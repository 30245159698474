<template>
<div class="container">
  <div class="d-flex players mt-5">
    <div class="flex-grow-1 mr-5">
        <h2 class="mb-2">Ranking</h2>
      <table class="table table-sm table-borderless ladder">
        <tbody>
          <tr v-for="player of players" :key="player.playerId">
            <td><h5>{{ player.firstName }}</h5></td>
            <td><h5>{{ player.totalScore }}</h5></td>
            <td>
              <button
                class="btn btn-outline-light ml-2"
                @click="selectPlayer(player.playerId)"
              >
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <player :player="currentPlayer" class="flex-grow-2"></player>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import Player from "./Player.vue";
export default {
  name: "Players",
  components: {
    Player,
  },
  props: ["title"],
  data() {
    return {
      currentPlayer: null,
      players: [],
    };
  },
  methods: {
    getPlayers() {
      axios
        .get("/api/players", {})
        .then((response) => {
          this.players = response.data;
          this.currentPlayer = this.players[0];
        })
        .catch(function(error) {
          alert(error);
        });
    },
    selectPlayer(id) {
      this.currentPlayer = this.players.find((p) => p.playerId == id);
    },
  },
  mounted() {
    this.getPlayers();
  },
};
</script>

<style scoped lang="scss">
.flex-grow-2 {
    flex-grow: 2;
}
.ladder {
    color: white;
}
</style>
