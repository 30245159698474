<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="score-hands">
        <table class="table table-sm table-borderless">
          <thead>
            <tr>
              <th>Hand</th>
              <th>Bid</th>
              <th></th>
              <th></th>
              <th>Below</th>
              <th>Above</th>
            </tr>
          </thead>
          <tbody>
            <tr
              @mouseover="hoverTarget = 'hand-' + hand.handId"
              @mouseleave="hoverTarget = 'none'"
              :class="{
                hover: hoverTarget == 'hand-' + hand.handId,
                inplay: hand.overUnderString == 'BID',
              }"
              v-for="hand of rubber.rubberScoreHands"
              :key="hand.number"
            >
              <td>
                <div class="cell first">
                  <div class="hand" :class="hand.dealer">
                    {{ hand.handNumber }}
                  </div>
                </div>
              </td>
              <td>
                <div class="cell">
                  <div class="bid">
                    <div class="number">{{ hand.tricksBid }}</div>
                    <div :class="'suit ' + hand.suit"></div>
                    <div class="double-type">{{ hand.doubleType }}</div>
                  </div>
                </div>
              </td>
              <td>
                <div class="cell">
                  <div class="over-under">{{ hand.overUnderString }}</div>
                </div>
              </td>
              <td>
                <div class="cell">
                  <div class="seat" :class="hand.vulnerability">
                    {{ hand.declarer.toUpperCase() }}
                  </div>
                </div>
              </td>
              <td>
                <div class="cell">
                  <div class="below" v-if="hand.below">
                    {{ hand.below.score }}
                  </div>
                </div>
              </td>
              <td>
                <div class="cell last">
                  <div class="above" v-for="(score, i) of hand.above" :key="i">
                    <span class="score-value">{{ score.score }}</span>
                    <span class="score-name">{{ score.name }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="score-pad">
        <div class="players d-flex">
          <div class="column d-flex justify-content-around">
            <div>
              {{ rubber.playerSeats.North.playerFirstName }}
              <br /><small>NORTH</small>
            </div>
            <div>
              {{ rubber.playerSeats.South.playerFirstName }}
              <br /><small>SOUTH</small>
            </div>
          </div>
          <div class="column d-flex justify-content-around">
            <div>
              {{ rubber.playerSeats.East.playerFirstName }}
              <br /><small>EAST</small>
            </div>
            <div>
              {{ rubber.playerSeats.West.playerFirstName }}
              <br /><small>WEST</small>
            </div>
          </div>
        </div>
        <div class="above d-flex">
          <div class="column d-flex flex-column justify-content-end">
            <div
              v-for="(hand,
              i) of rubber.northSouth.scoresAbove.slice().reverse()"
              :key="i"
              @mouseover="hoverTarget = hand.cssClass"
              @mouseleave="hoverTarget = 'none'"
              :class="{ hover: hoverTarget == hand.cssClass }"
              :title="hand.name"
            >
              {{ hand.score }}
            </div>
          </div>
          <div class="column d-flex flex-column justify-content-end">
            <div
              v-for="(hand, i) of rubber.eastWest.scoresAbove.slice().reverse()"
              :key="i"
              @mouseover="hoverTarget = hand.cssClass"
              @mouseleave="hoverTarget = 'none'"
              :class="{ hover: hoverTarget == hand.cssClass }"
              :title="hand.name"
            >
              {{ hand.score }}
            </div>
          </div>
        </div>

        <div class="below d-flex flex-column">
          <div v-for="n in rubber.gameCount" :key="n" class="d-flex">
            <div
              class="game column"
              :class="rubber.northSouth.gamesBelow[n - 1].gameStatus"
            >
              <div
                @mouseover="hoverTarget = hand.cssClass"
                @mouseleave="hoverTarget = 'none'"
                :class="{ hover: hoverTarget == hand.cssClass }"
                class="text-center"
                v-for="hand of rubber.northSouth.gamesBelow[n - 1].gameHands"
                :key="hand.handId"
              >
                {{ hand.score }}
              </div>
            </div>
            <div
              class="game column"
              :class="rubber.northSouth.gamesBelow[n - 1].gameStatus"
            >
              <div
                @mouseover="hoverTarget = hand.cssClass"
                @mouseleave="hoverTarget = 'none'"
                :class="{ hover: hoverTarget == hand.cssClass }"
                class="text-center"
                v-for="hand of rubber.eastWest.gamesBelow[n - 1].gameHands"
                :key="hand.handId"
              >
                {{ hand.score }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-grow-1 align-content-stretch">
            <div class="column"></div>
            <div class="column"></div>
          </div>
        </div>
        <div class="d-flex totals">
          <div class="column">
            <div class="text-muted">
              {{ rubber.northSouth.total }}
            </div>
            <div class="text-success">
              {{ rubber.northSouth.lead }}
            </div>
          </div>
          <div class="column">
            <div class="text-muted">
              {{ rubber.eastWest.total }}
            </div>
            <div class="text-success">
              {{ rubber.eastWest.lead }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  components: {},
  props: ["currentRubber"],
  data() {
    return {
      hoverTarget: "",
      rubber: {
        playerSeats: {
          North: {},
          South: {},
          East: {},
          West: {},
        },
        northSouth: {
          scoresAbove: [],
          gamesBelow: [],
        },
        eastWest: {
          scoresAbove: [],
          gamesBelow: [],
        },
        rubberScoreHands: Array,
      },
      name: "",
    };
  },
  watch: {
    currentRubber: function() {
      this.rubber = this.currentRubber;
    },
    // id: function() {
    //   console.log("watch:id")
    //   this.getRubber();
    // },
    // updated: function() {
    //   console.log("watch:updated")
    //   this.getRubber();
    // }
  },
  methods: {
    // getRubber() {
    //   axios
    //     .get("/api/rubber/" + this.id, {})
    //     .then((response) => {
    //       this.rubber = response.data;
    //       console.log(this.rubber);
    //     })
    //     .catch(function(error) {
    //       alert(error);
    //     });
    // },
  },
  mounted() {
    this.rubber = this.currentRubber;
    console.log(this.currentRubber.props);
    // this.getRubber();
  },
};
</script>

<style lang="scss" scoped>
$font-script: "Shadows Into Light Two", cursive;
$spacing: 6px;

$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 1);
$red: rgba(255, 0, 0, 0.5);

$score-pad-border-color: #666;
$score-hover: rgb(151, 207, 158, 0.5);
$score-hands-background: rgba(190, 190, 190, 0.4);
$score-hands-current: rgba(151, 207, 158, 0.7);
$seat-highlight: 3px solid rgba(3, 255, 58, 0.349);
$cell-border-color: rgba(151, 207, 158, 0.2);

.score-pad {
  background-image: url(../assets/seamless_paper_texture.png);
  color: #333;
  margin: 28px 0 0 0;
  padding: 10px;
  font-family: $font-script;
  font-size: 1.6em;
  font-weight: bold;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);

  .column {
    width: 50% !important;

    &:first-child {
      border-right: 1px solid;
    }

    div {
      text-align: center;
      cursor: pointer;
    }

    .hover {
      background: $score-hover;
    }
  }

  small {
    display: block;
    font-size: 14px;
  }

  .above,
  .below,
  .totals {
    border-top: 3px double $score-pad-border-color;
  }
  .above,
  .below {
    min-height: 260px;
  }
  .game {
    padding: 0.2em;
    border-bottom: 1px solid $score-pad-border-color;

    &.playing {
      border-bottom: none;
    }
  }
  .column {
    padding: 0.2em;
  }
}

.score-hands {

  .table {
    color: $white-9;

    tr .cell {
      cursor: pointer;
    }
    tr.hover .cell {
      background: $score-hover;
    }
    tr.inplay .cell {
      background: $score-hands-current;
    }
    th {
      font-weight: normal;
      font-size: 16px;
      padding: 0 $spacing;
    }
    td {
      padding: 0;
    }
  }

  .cell {
    display: flex;
    align-items: center;
    background-color: $score-hands-background;
    height: 50px;
    margin: $spacing 0;

    &.first {
      padding-left: $spacing;
      border-radius: $spacing 0 0 $spacing;
    }
    &.last {
      padding-right: $spacing;
      border-radius: 0 $spacing $spacing 0;
    }
  }

  .below {
    width: 40px;
    text-align: right;
    color: black;
    font-size: 1.3em;
  }
  .above {
    margin-right: $spacing;
    color: black;

    .score-value {
      display: inline-block;
      width: 40px;
      text-align: right;
      margin-right: 0.2em;
      font-weight: 400;
      font-size: 1.3em;
    }
    .score-name {
      font-size: 1em;
    }
  }
  .number {
    font-size: 1.8em;
    margin-left: 0.2em;
    margin-right: 0.1em;
    text-align: right;
    color: black;
    font-weight: 400;
  }
  .over-under {
    font-size: 1.8em;
    margin-right: 0.6em;
    width: 30px;
    text-align: right;
    color: black;
    font-weight: 400;
  }
  .double-type {
    margin-left: 0.2em;
    font-size: 1.2em;
    color: black;
  }
  .bid {
    display: flex;
    align-items: center;
  }

  .hand {
    color: $white-8;
  }

  .hand {
    width: 34px;
    height: 34px;
    border: 3px solid $cell-border-color;
    border-radius: 4px;
    text-align: center;
    padding-top: 2px;
    margin-right: $spacing;

    &.north {
      border-top: $seat-highlight;
    }
    &.south {
      border-bottom: $seat-highlight;
    }
    &.east {
      border-right: $seat-highlight;
    }
    &.west {
      border-left: $seat-highlight;
    }
  }

  .seat {
    width: 68px;
    border: 2px solid $cell-border-color;
    border-radius: 4px;
    color: black;
    text-align: center;
    padding-top: 4px 0 4px 0;
    font-size: 1.1em;

    &.vulnerable {
      border-color: $red;
    }
  }
}
</style>
