<template>
  <header>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-bridge-green box-shaddow"
    >
      <div class="container">
        <a class="navbar-brand" href="#">Rubber-dub-club</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
           

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Series
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li v-for="s of series" :key="s.id">
                  <router-link class="dropdown-item" :to="'/series/' + s.id"
                    >{{ s.id }}:{{ s.title }}</router-link
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'Players' }" class="nav-link"
                >Players</router-link
              >
            </li>
             <li class="nav-item">
              <router-link :to="{ name: 'Home' }" class="nav-link"
                >Home</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import axios from "axios";
export default {
  name: "NavMenu",
  data() {
    return {
      series: Array,
    };
  },
  methods: {
    getSeries() {
      axios
        .get("/api/series", {})
        .then((response) => {
          this.series = response.data;
        })
        .catch(function(error) {
          alert(error);
        });
    },
  },
  mounted() {
    this.getSeries();
  },
};
</script>

<style lang="scss">
$font-script: "Shadows Into Light Two", cursive;
$bridge-green: #101f06;

.navbar {
  z-index: 1;
}
.bg-bridge-green {
  background-color: $bridge-green;
}

.navbar-brand {
  font-family: $font-script;
  font-size: 2em !important;
  margin-top: -8px;
  margin-bottom: -8px;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
</style>
