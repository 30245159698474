import * as Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as signalR from "@aspnet/signalr";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// App is root component
const app = Vue.createApp(App).use(router)

app.config.globalProperties.$nick = { name: "nicholas" };

app.config.globalProperties.$connection = new signalR.HubConnectionBuilder()
  .withUrl("/bridgeHub", {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .configureLogging(signalR.LogLevel.Information)
  .build();

app.config.globalProperties.$connection.start().then(() => {
  console.log("SignalR Connected.");
  app.mount("#app");
});