import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/components/Home.vue';
// import Series from '@/components/Series.vue';
import SeriesView from '@/components/SeriesView.vue';
import Players from '@/components/Players.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/Series',
    //     name: 'Series',
    //     component: Series        
    // },
    {
        path: '/Series/:id',
        name: 'SeriesView',
        component: SeriesView,
        props: true
    },    
    {
        path: '/Players',
        name: 'Players',
        component: Players,
        props: {title: 'Player List'}
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;