<template>
  <div class="header-background"></div>
  <div class="container header">
    <div class="row mt-3 mb-3">
      <div class="col-4 col-sm-4">
        <div class="row">
          <div class="col-12 col-sm-12 rubber-title">
            Rubber {{ currentRubber.rubberNumber }}
          </div>
          <div class="col-12 col-sm-12 rubber-subtitle">{{ series.title }}</div>
          <div class="col-12">
            <ul class="totals totals-lg">
              <li v-for="player of series.seriesPlayers" :key="player">
                <span>{{ player.firstName }}</span>
                <span
                  class="score"
                  :class="{ 'number-negative': player.score < 0 }"
                  >{{ player.score }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-8 col-sm-8">
        <div class="d-flex rubbers">
          <div
            v-for="rubber of series.rubbers"
            :key="rubber.rubberId"
            @click="changeRubber(rubber)"
            class="mini-pad-wrapper"
            :class="{ selected: rubber.rubberId == currentRubber.rubberId }"
          >
            <div class="d-flex mini-pad">
              <div class="mini-pad-column">
                <div class="names">Nick&amp;<br />Lance</div>
                <div>
                  {{ rubber.northSouth.lead }}
                </div>
              </div>
              <div class="mini-pad-column">
                <div class="names">Lance&amp;<br />Matt</div>
                <div>
                  {{ rubber.eastWest.lead }}
                </div>
              </div>
            </div>
            <div class="mini-pad-label">{{ rubber.rubberNumber }}</div>
          </div>
        </div>
        <div class="d-flex flex-row-reverse">
          <ul class="totals totals-sm">
            <li v-for="player of series.seriesPlayers" :key="player">
              <span>{{ player.firstName }}</span>
              <span
                class="score"
                :class="{ 'number-negative': player.score < 0 }"
                >{{ player.score }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <rubber
      v-if="currentRubber.rubberId"
      :currentRubber="currentRubber"
    ></rubber>
  </div>
</template>

<script>
import axios from "axios";
import Rubber from "./Rubber";
export default {
  components: {
    Rubber,
  },
  props: ["id"],
  data() {
    return {
      rubberNumber: 1,
      rubberUpdated: Date.now(), // TODO Need to test
      series: Array,
      currentRubber: {},
    };
  },
  watch: {
    id() {
      this.getSeries();
    },
  },
  methods: {
    hub() {
      this.$connection.invoke("SendMessage", "nick", "Yes");
      this.getSeries();
    },
    getSeries() {
      axios
        .get("/api/series/" + this.id, {})
        .then((response) => {
          this.series = response.data;
          this.currentRubber = this.series.rubbers[0];
        })
        .catch(function(error) {
          alert(error);
        });
    },
    changeRubber(rubber) {
      this.currentRubber = rubber;
      this.rubberUpdated = Date.now();
    },
  },
  mounted() {
    this.getSeries();

    this.$connection.on("ScoreUpdated", (user, message) => {
      console.log("Series " + this.id, user, message);
      this.getSeries();
      this.rubberUpdated = Date.now();
    });
  },
};
</script>

<style scoped lang="scss">
$font-script: "Shadows Into Light Two", cursive;

.header-background {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  height: 175px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 13px 0px rgb(0 0 0 / 20%);
}

.rubber-title {
  color: #fff;
  font-size: 3em;
  margin: 0;
  line-height: 1em;
}
.rubber-subtitle {
  color: #ddd;
  margin-bottom: 16px;
}
.series-scores {
  background-color: rgba(200, 200, 200, 0.5);
}

.rubbers {
  justify-content: flex-end;
}

.mini-pad {
  width: 112px;
  padding: 2px;
  background: #bbb;
  color: #333;
  font-family: $font-script;
  font-weight: 700;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0);

  .mini-pad-column {
    width: 50%;

    > .names {
      font-size: 0.8em;
    }

    &:first-child {
      border-right: 1px solid #666;
    }

    > div {
      padding: 3px 0;
      text-align: center;
    }

    > div:first-child {
      border-bottom: 3px double #666;
      font-size: 1em;
      padding: 0.5em;
    }
    > div:last-child {
      font-size: 1.2em;
      padding: 0.7em 0;
    }
  }
}

.mini-pad-label {
  padding-top: 2px;
  text-align: center;
  color: #ccc;
}

.mini-pad-wrapper {
  margin-left: 24px;

  &:hover,
  &.selected {
    cursor: pointer;

    .mini-pad {
      background: #eee;
    }

    .mini-pad-label {
      color: white;
    }
  }
}

@media (min-width: 0) and (max-width: 1199.98px) {
  .mini-pad {
    display: none !important;
  }
  .mini-pad-label {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 6px 12px;
    text-align: center;
    color: #ccc;
  }
  .mini-pad-wrapper {
    margin-left: 12px;

    &:hover,
    &.selected {
      .mini-pad-label {
        background-color: #333;
      }
    }
  }
}

.totals {
  display: inline;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
  font-family: $font-script;
  font-weight: bold;
  font-size: 1.4em;
  padding: 5px;
  list-style: none;
  margin-bottom: 0;

  &.totals-sm {
    margin-top: 1em;
    display: none;
  }

  li {
    display: inline-block;
    margin-right: 10px;

    .score {
      color: rgb(14, 85, 14);
      margin-left: 0.3em;
      margin-right: 0.2em;

      &.number-negative {
        color: rgb(121, 16, 16);
      }
    }
  }
}

@media (min-width: 0) and (max-width: 1399.98px) {
  .totals {
    font-size: 1em;
  }
}
@media (min-width: 0) and (max-width: 1199.98px) {
  .totals {
    &.totals-lg {
      display: none;
    }
    &.totals-sm {
      display: inline;
    }
  }
}
@media (min-width: 0) and (max-width: 767.98px) {
  .rubber-title {
    font-size: 2em;
  }
  .rubber-subtitle {
    font-size: 1em;
    margin-top: 0.2em;
  }
}
@media (min-width: 0) and (max-width: 575.98px) {
  .totals {
    font-size: 0.9em;
  }
}
</style>
